/*
 * Common Messages
 *
 * This contains all the text for that are used in multiple pages.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.common';

export default defineMessages({
  sort: {
    id: `${scope}.sort`,
    defaultMessage: 'Sort',
  },
  nextPage: {
    id: `${scope}.nextPage`,
    defaultMessage: 'Next page',
  },
  prevPage: {
    id: `${scope}.prevPage`,
    defaultMessage: 'Previous page',
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: 'Rows per page:',
  },
  displayRows: {
    id: `${scope}.displayRows`,
    defaultMessage: 'of',
  },
  print: {
    id: `${scope}.print`,
    defaultMessage: 'Print',
  },
  downloadCsv: {
    id: `${scope}.downloadCsv`,
    defaultMessage: 'Download CSV',
  },
  viewColumns: {
    id: `${scope}.viewColumns`,
    defaultMessage: 'View columns',
  },
  viewColumnsTitle: {
    id: `${scope}.viewColumnsTitle`,
    defaultMessage: 'Show columns',
  },
  viewColumnsTitleAria: {
    id: `${scope}.viewColumnsTitleAria`,
    defaultMessage: 'Show/hide table columns',
  },
  any: {
    id: `${scope}.any`,
    defaultMessage: 'Any',
  },
  unknown: {
    id: `${scope}.unknown`,
    defaultMessage: 'Unknown',
  },
  wctodo: {
    id: `${scope}.wctodo`,
    defaultMessage: 'Welcome call to do',
  },
  wcdone: {
    id: `${scope}.wcdone`,
    defaultMessage: 'Welcome call done',
  },
  wetodo: {
    id: `${scope}.wetodo`,
    defaultMessage: 'Welcome email to do',
  },
  wedone: {
    id: `${scope}.wedone`,
    defaultMessage: 'Welcome email done',
  },
  confirmed: {
    id: `${scope}.confirmed`,
    defaultMessage: 'Confirmed',
  },
  dropped: {
    id: `${scope}.dropped`,
    defaultMessage: 'Dropped',
  },
  postponed: {
    id: `${scope}.postponed`,
    defaultMessage: 'Postponed',
  },
  notVer: {
    id: `${scope}.notVer`,
    defaultMessage: 'Not verified',
  },
  ver: {
    id: `${scope}.ver`,
    defaultMessage: 'Verified',
  },
  verOxfam: {
    id: `${scope}.verOxfam`,
    defaultMessage: 'Verified Oxfam',
  },
  clrFltr: {
    id: `${scope}.clrFltr`,
    defaultMessage: 'Clear filter',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  noOption: {
    id: `${scope}.noOption`,
    defaultMessage: 'No options',
  },
  both: {
    id: `${scope}.both`,
    defaultMessage: 'Both',
  },
  none: {
    id: `${scope}.none`,
    defaultMessage: 'None',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  canceled: {
    id: `${scope}.canceled`,
    defaultMessage: 'Canceled',
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading',
  },
  female: {
    id: `${scope}.female`,
    defaultMessage: 'Female',
  },
  male: {
    id: `${scope}.male`,
    defaultMessage: 'Male',
  },
  neutral: {
    id: `${scope}.neutral`,
    defaultMessage: 'Neutral',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  complete: {
    id: `${scope}.complete`,
    defaultMessage: 'Complete',
  },
  incomplete: {
    id: `${scope}.incomplete`,
    defaultMessage: 'Incomplete',
  },
  sizeXS: {
    id: `${scope}.sizeXS`,
    defaultMessage: 'XS',
  },
  sizeS: {
    id: `${scope}.sizeS`,
    defaultMessage: 'S',
  },
  sizeM: {
    id: `${scope}.sizeM`,
    defaultMessage: 'M',
  },
  sizeL: {
    id: `${scope}.sizeL`,
    defaultMessage: 'L',
  },
  sizeXL: {
    id: `${scope}.sizeXL`,
    defaultMessage: 'XL',
  },
  sizeNC: {
    id: `${scope}.sizeNC`,
    defaultMessage: 'NC',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: 'Role',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal code',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  captain: {
    id: `${scope}.captain`,
    defaultMessage: 'Captain',
  },
  walker: {
    id: `${scope}.walker`,
    defaultMessage: 'Walker',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  emerCon: {
    id: `${scope}.emerCon`,
    defaultMessage: 'Emergency contact',
  },
  emerConPhone: {
    id: `${scope}.emerConPhone`,
    defaultMessage: 'Emergency phone',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Telephone',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of birth',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1: {
    id: `${scope}.address1`,
    defaultMessage: 'Additional address',
  },
  regDate: {
    id: `${scope}.regDate`,
    defaultMessage: 'Registered date',
  },
  proceed: {
    id: `${scope}.proceed`,
    defaultMessage: 'Proceed',
  },
  confirmProceed: {
    id: `${scope}.confirmProceed`,
    defaultMessage: 'Do you wish to proceed?',
  },
  createTag: {
    id: `${scope}.createTag`,
    defaultMessage: 'Create tag',
  },
  textReq: {
    id: `${scope}.textReq`,
    defaultMessage: 'Text is required',
  },
  maxTextLen: {
    id: `${scope}.maxTextLen`,
    defaultMessage: 'Tag name should not be greater than 50 characters',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  tagName: {
    id: `${scope}.tagName`,
    defaultMessage: 'Tag name',
  },
  updateTagName: {
    id: `${scope}.updateTagName`,
    defaultMessage: 'Update tag name',
  },
  years: {
    id: `${scope}.years`,
    defaultMessage: 'years',
  },
  'B-1-324': {
    id: `${scope}.B-1-324`,
    defaultMessage: '1€ to 324€',
  },
  'C-325-749': {
    id: `${scope}."C-325-749"`,
    defaultMessage: '€325 to 749€',
  },
  'D-750-1124': {
    id: `${scope}."D-750-1124"`,
    defaultMessage: '€750 to 1124€',
  },
  'E-1125-1499': {
    id: `${scope}."E-1125-1499"`,
    defaultMessage: '€1125 to 1499€',
  },
  'F-1500<=': {
    id: `${scope}."F-1500<="`,
    defaultMessage: '> or equals to 1500€',
  },
  'A-0': {
    id: `${scope}."A-0"`,
    defaultMessage: '0€',
  },
  sandboxWarn: {
    id: `${scope}.sandboxWarn`,
    defaultMessage: 'You are viewing development sandbox',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Count',
  },
  inprogress: {
    id: `${scope}.inprogress`,
    defaultMessage: 'In progress',
  },
  refused: {
    id: `${scope}.refused`,
    defaultMessage: 'Refused',
  },
  activate: {
    id: `${scope}.activate`,
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: `${scope}.deactivate`,
    defaultMessage: 'Deactivate',
  },
  confirmation: {
    id: `${scope}.confirmation`,
    defaultMessage: 'Confirmation',
  },
  notneeded: {
    id: `${scope}.notneeded`,
    defaultMessage: 'Not needed',
  },
  nonveg: {
    id: `${scope}.nonveg`,
    defaultMessage: 'Non vegetarian',
  },
  veg: {
    id: `${scope}.veg`,
    defaultMessage: 'Vegetarian',
  },
  nomeal: {
    id: `${scope}.nomeal`,
    defaultMessage: 'Without meal',
  },
  aperitif: {
    id: `${scope}.aperitif`,
    defaultMessage: 'Solidarity aperitif',
  },
  no_aperitif: {
    id: `${scope}.no_aperitif`,
    defaultMessage: 'No aperitif',
  },
  mealNotBooked: {
    id: `${scope}.mealNotBooked`,
    defaultMessage: 'No meal booked',
  },
  meal: {
    id: `${scope}.meal`,
    defaultMessage: 'Meal',
  },
  onboarded: {
    id: `${scope}.onboarded`,
    defaultMessage: 'Boarded',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'Register',
  },
  hosting: {
    id: `${scope}.hosting`,
    defaultMessage: 'Hosting form',
  },
  adminDashboard: {
    id: `${scope}.adminDashboard`,
    defaultMessage: 'Admin dashboard',
  },
  eventDashboard: {
    id: `${scope}.eventDashboard`,
    defaultMessage: 'dashboard',
  },
  eventSetting: {
    id: `${scope}.eventSetting`,
    defaultMessage: 'Setting | global',
  },
  memberTagSetting: {
    id: `${scope}.memberTagSetting`,
    defaultMessage: 'Setting | Member tags',
  },
  volunteerTagSetting: {
    id: `${scope}.volunteerTagSetting`,
    defaultMessage: 'Setting | Volunteer tags',
  },
  teamTagSetting: {
    id: `${scope}.teamTagSetting`,
    defaultMessage: 'Setting | Team tag',
  },
  volunteerGrpSetting: {
    id: `${scope}.volunteerGrpSetting`,
    defaultMessage: 'Setting | Volunteer group',
  },
  eventTeamList: {
    id: `${scope}.eventTeamList`,
    defaultMessage: 'Team list',
  },
  eventVolunteerList: {
    id: `${scope}.eventVolunteerList`,
    defaultMessage: 'Volunteer list',
  },
  eventMemberList: {
    id: `${scope}.eventMemberList`,
    defaultMessage: 'Walker list',
  },
  eventSupporterList: {
    id: `${scope}.eventSupporterList`,
    defaultMessage: 'Supporter list',
  },
  eventVolunteerDetail: {
    id: `${scope}.eventVolunteerDetail`,
    defaultMessage: 'Volunteer detail',
  },
  memberDetail: {
    id: `${scope}.memberDetail`,
    defaultMessage: 'Member detail',
  },
  teamDetail: {
    id: `${scope}.teamDetail`,
    defaultMessage: 'Team detail',
  },
  supporterDetail: {
    id: `${scope}.supporterDetail`,
    defaultMessage: 'Supporter detail',
  },
  profilePage: {
    id: `${scope}.profilePage`,
    defaultMessage: 'User profile',
  },
  retry: {
    id: `${scope}.retry`,
    defaultMessage: 'Click here to retry',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
});
